import axios from 'axios'

// const state = () => ({})

// const getters = {}

// const mutations = {}

const actions = {
  async getPaProductHistory(context, credentials) {
    try {
      const res = await axios.post(process.env.VUE_APP_HISTORY_PA_GROUP_LIST, credentials)
      const data = res
      console.log('Response API History PA product group ::', data)

      if (res.status === 200 && res.data.isSuccess) {
        return res.data.data
      } else return false
    } catch (err) {
      console.error(err)
    }
  },

  async getCancerProductHistory(context, credentials) {
    try {
      const res = await axios.post(process.env.VUE_APP_HISTORY_CANCER_GROUP_LIST, credentials)
      const data = res
      console.log('Response API History Cancer product group ::', data)

      if (res.status === 200 && res.data.isSuccess) {
        return res.data.data
      } else return false
    } catch (err) {
      console.error(err)
    }
  },

  async getAutoProductHistory(context, credentials) {
    try {
      const res = await axios.post(process.env.VUE_APP_AUTO_PROMPT_HISTORY_LIST, credentials)
      const data = res
      console.log('Response API History AUTO product group ::', data)

      if (res.status === 200 && res.data.isSuccess) {
        return res.data.data
      } else return false
    } catch (err) {
      console.error(err)
    }
  },

  async getHomeProductHistory(context, credentials) {
    try {
      const res = await axios.post(process.env.VUE_APP_HOME_PROMPT_HISTORY_LIST, credentials)
      const data = res
      console.log('Response API History HOME product group ::', data)

      if (res.status === 200 && res.data.isSuccess) {
        return res.data.data
      } else return false
    } catch (err) {
      console.error(err)
    }
  },
  async getRenewalCmsHistory(context, credentials) {
    try {
      console.log('credentials', credentials)
      const res = await axios.post(process.env.VUE_APP_RENEWAL_CMS_LIST, credentials)
      console.log('res', res)
      const data = res
      console.log('Response API Renewal CMS  ::', data)
      if (res.status === 200 && res.data.isSuccess) {
        return res.data.data
      } else return false
    } catch (err) {
      console.error(err)
    }
  },
}

export default {
  namespaced: true,
  // state,
  // getters,
  // mutations,
  actions,
}
