import axios from 'axios'
import router from '../../router'

/**
 * initial state
 */
const state = () => ({
  // todo
})

/**
 * getters
 */
const getters = {
  // todo
}

/**
 * mutations
 */
const mutations = {
  // todo
}

/**
 * actions
 */
const actions = {
  /**
   * List Quotation
   */
  /**
   * List Quotation
   */
  getProductCode(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API list productcode params ::', credentials)
      axios
        .get(process.env.VUE_APP_RENEWAL_CMS_GET_PRODECT)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },

  /**
   * View Quotation Detail
   * @param paTamjaiId
   * @return {array}
   */
  viewQuotationDetail(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_RENEWAL_CMS_GET_DETAIL + '/' + credentials.quotationId)
        .then((response) => {
          console.log('API view quotation detail ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              if (response.data.data.hash_id == null) {
                resolve('No content')
                console.error('No content')
                router.push({ name: 'history' })
              }
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          } else reject(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  /**
   * Approve quotation function
   */

  reportRawData(context, credential) {
    return new Promise((resolve, reject) => {
      // console.log('report cia params ::', credential)
      const params = {
        startDate: credential.startDate,
        endDate: credential.endDate,
      }
      console.log('report Excel params ::', params)
      axios
        .post(process.env.VUE_APP_RENEWAL_CMS_REPORT_RAW, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  updatedPayment(context, credential) {
    return new Promise((resolve, reject) => {
      const param = credential
      console.log('API update payment ::', param)
      axios
        .post(process.env.VUE_APP_UPLOAD_PAYMENT_RENEWAL, param)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
