import Vue from 'vue'
import Vuex from 'vuex'
import staff from './modules/staff'
import history from './modules/History.js'
import cancerPrompt from './modules/cancerPrompt.js'
import homePrompt from './modules/homePrompt.js'
import autoPrompt from './modules/autoPrompt.js'
import paTamjai from './modules/paTamjai'
import renewalCMS from './modules/renewalCMS.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    staff,
    history,
    cancerPrompt,
    homePrompt,
    autoPrompt,
    paTamjai,
    renewalCMS,
  },
  strict: process.env.NODE_ENV !== 'production',
})
