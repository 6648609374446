import axios from 'axios'
import router from '../../router'

/**
 * initial state
 */
const state = () => ({
  // todo
})

/**
 * getters
 */
const getters = {
  // todo
}

/**
 * mutations
 */
const mutations = {
  // todo
}

/**
 * actions
 */
const actions = {
  /**
   * List Quotation
   */
  /**
   * List Quotation
   */
  listQuotation(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API list quotation params ::', credentials)
      axios
        .post(process.env.VUE_APP_HOME_PROMPT_HISTORY_LIST, credentials)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },

  /**
   * View Quotation Detail
   * @param paTamjaiId
   * @return {array}
   */
  viewQuotationDetail(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_HOME_PROMPT_VIEW + '/' + credentials.quotationId)
        .then((response) => {
          console.log('API view quotation detail ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              if (response.data.data.id == null) {
                resolve('No content')
                console.error('No content')
                router.push({ name: 'history' })
              }
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          } else reject(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  /**
   * Approve quotation function
   */
  approveQuotation(context, credential) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_PA_TAMJAI_APPROVE, {
          smeFireinsuranceId: credential.quotationId,
        })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  reportCia(context, credential) {
    return new Promise((resolve, reject) => {
      console.log('report cia params ::', credential)
      const params = {
        startDate: credential.startDate,
        endDate: credential.endDate,
        referral_type: credential.referral_type,
      }

      axios
        .post(process.env.VUE_APP_REPORT_CIA_HOME, params, {
          responseType: 'blob',
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  reportCiaStatus2(context, credential) {
    return new Promise((resolve, reject) => {
      const params = {
        startDate: credential.startDate,
        endDate: credential.endDate,
        referral_type: credential.referral_type,
      }

      axios
        .post(process.env.VUE_APP_REPORT_CIA_STATUS_2_HOME, params, {
          responseType: 'blob',
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  reportExcel(context, credential) {
    return new Promise((resolve, reject) => {
      // console.log('report cia params ::', credential)
      const params = {
        startDate: credential.startDate,
        endDate: credential.endDate,
      }
      console.log('report Excel params ::', params)
      axios
        .post(process.env.VUE_APP_REPORT_EXCEL_HOME, params, {
          responseType: 'blob',
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  reportRawData(context, credential) {
    return new Promise((resolve, reject) => {
      console.log('report raw data params ::', credential)
      const params = {
        startDate: credential.startDate,
        endDate: credential.endDate,
      }

      axios
        .get(process.env.VUE_APP_REPORT_RAW + '?startDate=' + params.startDate + '&endDate=' + params.endDate, {
          responseType: 'blob',
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  checkPaymentStatus() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PA_TAMJAI_CHECK_PAYMENT)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  getRejectList() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PA_TAMJAI_REJECT_LIST)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  rejectQuotation(context, credential) {
    return new Promise((resolve, reject) => {
      console.log('API reject quotation ::', context, credential)
      axios
        .post(process.env.VUE_APP_PA_TAMJAI_REJECT, credential)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  approveCustomerImage(context, credential) {
    return new Promise((resolve, reject) => {
      const param = '/' + credential.customerId
      console.log('API update customer ::', param)
      axios
        .post(process.env.VUE_APP_HOME_PROMPT_IMAGE_APPROVE + param)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
    })
  },
  updatedPayment(context, credential) {
    return new Promise((resolve, reject) => {
      const param = credential
      console.log('API update payment ::', param)
      axios
        .post(process.env.VUE_APP_UPLOAD_PAYMENT_HOME, param)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
